var render = function () {
  var _vm$tableSections, _vm$tableFields;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['reporting-table', !((_vm$tableSections = _vm.tableSections) !== null && _vm$tableSections !== void 0 && _vm$tableSections.length) ? 'empty' : '']
  }, [_c('simplebar', {
    ref: "simplebarEl",
    class: ['reporting-table__wrapper', _vm.selectedCol === 0 ? 'first-col-selected' : '', _vm.selectedCol + 2 === ((_vm$tableFields = _vm.tableFields) === null || _vm$tableFields === void 0 ? void 0 : _vm$tableFields.length) ? 'last-col-selected' : ''],
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', {
    ref: "theadEl",
    staticClass: "reporting-table__thead",
    style: "top: ".concat(_vm.scrolledHeight, "px; transition: none !important;")
  }, [_c('tr', _vm._l(_vm.tableFields, function (field, index) {
    var _vm$tableSections2;

    return _c('th', {
      key: field.key,
      class: [index - 1 === _vm.selectedCol ? 'selected' : '', !((_vm$tableSections2 = _vm.tableSections) !== null && _vm$tableSections2 !== void 0 && _vm$tableSections2.length) && !_vm.editMode ? 'add-border' : '']
    }, [_c('div', {
      staticClass: "cell"
    }, [index === 0 ? _c('div', {
      staticClass: "reporting-table__header"
    }, [_vm.editMode && _vm.showPeriod ? _c('p', [_vm._v("PERIOD")]) : _vm._e(), _vm.editMode ? _c('p', [_vm._v("STATUS")]) : _vm._e()]) : _c('div', {
      staticClass: "reporting-table__header text-right"
    }, [_vm.showPeriod ? _c('p', [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.label))]) : _vm._e(), _c('p', [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.labelInfo))])])])]);
  }), 0), _vm.editMode ? _c('tr', _vm._l(_vm.tableFields, function (field, index) {
    var _vm$tableSections3;

    return _c('th', {
      key: field.key,
      class: [index - 1 === _vm.selectedCol ? 'selected' : '', !((_vm$tableSections3 = _vm.tableSections) !== null && _vm$tableSections3 !== void 0 && _vm$tableSections3.length) ? 'add-border' : '']
    }, [_c('div', {
      staticClass: "cell"
    }, [index === 0 ? _c('div', {
      staticClass: "reporting-table__header"
    }, [_vm._v(" ACTION ")]) : _c('div', {
      staticClass: "text-right"
    }, [(field === null || field === void 0 ? void 0 : field.labelInfo) !== 'published' ? _c('sygni-rect-button', {
      staticClass: "filled gn-secondary inline small",
      on: {
        "click": function click($event) {
          return _vm.$emit('publish', index);
        }
      }
    }, [_vm._v("PUBLISH")]) : _vm._e()], 1)])]);
  }), 0) : _vm._e()]), _vm._l(_vm.tableSections, function (section, i) {
    var _vm$tableSections4, _section$items;

    return [_c('thead', {
      style: "top: ".concat(_vm.scrolledHeight + _vm.theadHeight - 3, "px; transition-none !important;")
    }, [_c('tr', [_c('th', {
      class: [i + 1 === ((_vm$tableSections4 = _vm.tableSections) === null || _vm$tableSections4 === void 0 ? void 0 : _vm$tableSections4.length) && (section === null || section === void 0 ? void 0 : (_section$items = section.items) === null || _section$items === void 0 ? void 0 : _section$items.length) === 0 ? 'add-border' : '']
    }, [_c('div', {
      staticClass: "cell"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(section.name))]), _c('div', {
      staticClass: "reporting-table__header text-left"
    })])]), _vm._l(_vm.generateTableHeaders(section), function (field, index) {
      var _vm$tableSections5, _section$items2;

      return _c('th', {
        key: field.key,
        class: [index === _vm.selectedCol ? 'selected' : '', i + 1 === ((_vm$tableSections5 = _vm.tableSections) === null || _vm$tableSections5 === void 0 ? void 0 : _vm$tableSections5.length) && (section === null || section === void 0 ? void 0 : (_section$items2 = section.items) === null || _section$items2 === void 0 ? void 0 : _section$items2.length) === 0 ? 'add-border' : '']
      }, [_c('div', {
        staticClass: "cell"
      }, [_c('div', {
        staticClass: "reporting-table__header text-right"
      }, [_c('p', [_vm._v(_vm._s(field.label))]), _c('p', [_vm._v(_vm._s(field.labelInfo))])])])]);
    })], 2)]), _c('tbody', {
      ref: "tbodyEl-".concat(i),
      refInFor: true
    }, _vm._l(section.items, function (row, counter) {
      var _section$items3;

      return _c('tr', {
        class: "nest-".concat(row.level - 1, " ").concat(row.abstract ? 'abstract' : '')
      }, [_c('td', {
        class: [counter + 1 === (section === null || section === void 0 ? void 0 : (_section$items3 = section.items) === null || _section$items3 === void 0 ? void 0 : _section$items3.length) ? 'add-border' : '']
      }, [_c('div', {
        class: ['cell', row.level <= 2 ? 'name' : '']
      }, [_c('span', [_vm._v(_vm._s(row.name))])])]), row.abstract ? _vm._l(4, function (i, index) {
        var _section$items4;

        return _c('td', {
          key: i,
          class: [index === _vm.selectedCol ? 'selected' : '', counter + 1 === (section === null || section === void 0 ? void 0 : (_section$items4 = section.items) === null || _section$items4 === void 0 ? void 0 : _section$items4.length) ? 'add-border' : ''],
          on: {
            "click": function click($event) {
              return _vm.$emit('selectCol', index);
            }
          }
        }, [_c('div', {
          staticClass: "cell text-right total"
        }, [_vm._v("-")])]);
      }) : _vm._l(row.values, function (item, index) {
        var _section$items5;

        return _c('td', {
          class: [index === _vm.selectedCol ? 'selected' : '', counter + 1 === (section === null || section === void 0 ? void 0 : (_section$items5 = section.items) === null || _section$items5 === void 0 ? void 0 : _section$items5.length) ? 'add-border' : ''],
          on: {
            "click": function click($event) {
              return _vm.$emit('selectCol', index);
            }
          }
        }, [_c('div', {
          staticClass: "cell"
        }, [_c('sygni-plain-input', {
          attrs: {
            "disabled": !item.editable,
            "display-format": "number",
            "value": item.value,
            "placeholder": "-"
          },
          on: {
            "blur": function blur($event) {
              return _vm.updateTableValue($event, item.id);
            }
          }
        })], 1)]);
      })], 2);
    }), 0)];
  })], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }